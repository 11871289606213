<style lang="less">
.auth-user-form {
  .role-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 15px;
    display: flex;
    li {
      line-height: 24px;
      cursor: pointer;
      padding: 0 10px;
      color: rgba(0, 0, 0, 0.65);
      background-color: #F5F5F5;
      border: 1px solid #D9D9D9;
      transition: all .3s;
      position: relative;
      border-radius: 2px;
      & + li {
        margin-left: 11px;
      }
      &:hover, &.active {
        color: #F4628F;
        border-color: #FFCFD7;
      }
      &.active {
        background-color: #FFCFD7;
      }
    }
  }
  .action-cate {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-top: 17px;
    margin-left: 10px;
    display: flex;
    border-bottom: 1px solid #E8E8E8;
    li {
      border-bottom: 3px solid transparent;
      color: rgba(0, 0, 0, 0.65);
      cursor: pointer;
      padding: 0 10px;
      font-size: 14px;
      & + li {
        margin-left: 10px;
      }
      &:hover {
        color: #FB6D8F;
      }
      &.active {
        border-bottom-color: #FB6D8F;
      }
    }
  }
  .action-plane {
    margin-left: 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    .action-values {
      display: flex;
      flex-direction: column;
      height: 200px;
      overflow: auto;
      position: relative;
      .check-all {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    .action-seleced {
      overflow: auto;
      max-height: 200px;
      flex-wrap: wrap;
    }
  }
  .add-user {
    background-color: #FFF;
    border-left: 1px solid #EEE;
    padding-left: 20px;
  }
  .sub-title {
    font-size: 14px;
    font-weight: 500;
    position: relative;
    left: -16px;
    display: flex;
    align-items: center;
    &::before {
      content: '';
      margin-right: 20px;
      display: inline-block;
      width: 4px;
      height: 24px;
      background: #F4628F;
      opacity: 1;
    }
  }
  .role-plane {
    margin-top: 10px;
    display: flex;
    align-items: flex-start;
  }
  .action-seleced {
    position: relative;
    .fm-tag {
      margin-left: 0;
      margin-right: 5px;
      margin-top: 5px;
    }
  }
  .action-values {
    .fm-checkbox-group.fm-checkbox-group-vertical > .fm-checkbox {
      margin-top: 0;
    }
  }
}
.blue-theme {
  .auth-user-form {
    .role-list {
      li {
        &:hover, &.active {
          color: #005AFF;
          border-color: rgba(0, 90, 255, .2);
        }
        &.active {
          background-color: rgba(0, 90, 255, .2);
        }
      }
    }
    .action-cate {
      li {
        &:hover {
          color: #005AFF;
        }
        &.active {
          border-bottom-color: #005AFF;
        }
      }
    }
    .sub-title {
      &::before {
        background: #005AFF;
      }
    }
  }
}
.yellow-theme {
  .auth-user-form {
    .role-list {
      li {
        &.active, &.active:hover {
          color: #FFF;
          border-color: rgb(108, 87, 60, .2);
        }
        &:hover {
          color: #6C573C;
          border-color: rgb(108, 87, 60, .2);
        }
        &.active {
          background-color: rgb(108, 87, 60, .2);
        }
      }
    }
    .action-cate {
      li {
        &:hover {
          color: #6C573C;
        }
        &.active {
          border-bottom-color: #6C573C;
        }
      }
    }
    .sub-title {
      &::before {
        background: #6C573C;
      }
    }
  }
}
</style>

<template>
  <fm-modal class="auth-user-form" :mask-closable="false" v-model="show" theme="mh-blackt" width="800px">
    <div slot="header">{{data && data.id ? (data.name + '修改') : '新增'}}</div>
    <fm-form ref="form" label-width="100px">
      <div class="sub-title" style="margin-bottom: 10px;">账号和角色</div>
      <fm-form-item label="用户名">
        <fm-input-new v-model="userData.name" required v-verifier placeholder="用户名"/>
      </fm-form-item>
      <fm-form-item label="账号">
        <fm-input-new v-model="userData.phone" :disabled="Boolean(data && data.id)" required v-verifier placeholder="账号"/>
      </fm-form-item>
      <fm-form-item label="角色权限">
        <fm-select v-model="userData.roleIds" block filterable multiple>
          <fm-option v-for="sys in sysRoles" :key="sys.key" :value="sys.key" :label="sys.label"></fm-option>
        </fm-select>
      </fm-form-item>
    </fm-form>

    <div class="sub-title" style="margin: 10px 0;">数据权限</div>

    <ul class="role-list" v-if="userRoleDatas.length">
      <li v-for="role in userRoleDatas" :class="{active: role.key === chooseRoleId}" @click="setChooseRoleId(role.key === chooseRoleId ? null : role.key)" :key="role.key">{{role.label}}</li>
    </ul>
    <div v-else style="color: #aaa;margin-left: 10px;">请先点击上方选择角色</div>
    
    <template v-if="userRoleDatas.length && cates.length && chooseRoleId">
      <ul class="action-cate">
        <li v-for="cate in cates" :class="{active: chooseCate && (chooseCate.key === cate.key)}" :key="cate.key" @click="setChooseCate(cate)">{{cate.label}}</li>
      </ul>

      <div class="role-plane">
        <div class="action-plane">
          <template v-if="chooseRoleId">
            <div class="action-seleced" style="margin-bottom: 10px;" v-if="action.values.length">
              <fm-tag closable @close="onRemove(item)" v-for="item in action.values" :key="item.key">{{item.label}}</fm-tag>
            </div>
            <div style="margin-bottom: 10px;position: relative;">
              <fm-input-new v-model="action.search">
                <i class="fmico fmico-search" slot="prefix"/>
              </fm-input-new>
              <template v-if="chooseCate && chooseCate.key === 'org'">
                <fm-btn style="margin-left: 10px;" @click="selectAll">全选</fm-btn>
                <fm-btn @click="selectOff">反选</fm-btn>
              </template>
            </div>
            <div class="action-values" v-loadingx="loading.cate" v-if="chooseCate">
              <fm-radio-group v-if="chooseCate.key === 'worker'" vertical @change="valuesChange" :value="action.values.length ? action.values[0].key : null">
                <fm-radio v-for="values in actionListsShow" :label="values.label" :key="values.key" :value="values.key"></fm-radio>
              </fm-radio-group>
              <fm-checkbox-group v-else vertical @change="valuesChange" :value="action.values.map(v => v.key)">
                <fm-checkbox v-for="values in actionListsShow" :label="values.key" :key="values.key">{{values.label}}</fm-checkbox>
              </fm-checkbox-group>
            </div>
          </template>
          <div v-else style="color: #aaa;margin-left: -20px;">请先点击上方所要关联的角色</div>
        </div>
        <div style="flex:1;" v-if="chooseCate && chooseCate.key === 'worker'">
          <fm-form ref="add" class="add-user" label-alone label-align="left">
            <div style="line-height: 40px;font-weight: 800;color: #000;">新增员工</div>
            <fm-form-item label="姓名">
              <fm-input-new placeholder="姓名" required v-verifier v-model="newUser.name" />
            </fm-form-item>
            <fm-form-item label="工号">
              <fm-input-new placeholder="工号" v-model="newUser.code" />
            </fm-form-item>
            <fm-form-item label="身份证号">
              <fm-input-new placeholder="身份证号" v-model="newUser.idNo" />
            </fm-form-item>
            <fm-form-item>
              <fm-btn @click="addUser" v-loadingx="loading.add">保存</fm-btn>
            </fm-form-item>
          </fm-form>
        </div>
      </div>
    </template>

    <div slot="footer" class="modal-footer-btns">
      <fm-btn type="primary" tint @click="submit" v-loadingx="loading.submit">确定</fm-btn>
      <fm-btn @click="show = false" v-loadingx="loading.submit">取消</fm-btn>
    </div>
  </fm-modal>
</template>

<script>
import { workerRequest, userRequest, ucenterRequest } from '@/api'

const cates = [
  { key: 'worker', label: '职工', api: 'loadAllWorkerList' },
  { key: 'org', label: '机构', api: 'loadOrgList' }
]

function getpositionIdsByOrgIds ({orgIds, child, positionIds, find}) {
  if (child && child.length > 0) {
    child.forEach((v) => {
      let newFind = find || orgIds.includes(v.id)
      if (newFind && v.positions && v.positions.length > 0) {
        v.positions.forEach(v1 => positionIds.push(v1.id))
      }
      getpositionIdsByOrgIds({orgIds, child: v.child, positionIds, find: newFind})
    })
  }
}

export default {
  props: {
    orgTree: {
      type: Array, default () {
        return []
      }
    }
  },
  data () {
    return {
      cates: [],
      data: null,
      show: false,
      userData: {
        name: null,
        phone: null,
        roleIds: []
      },
      userAuthData: [],
      newUser: {
        name: null,
        code: null,
        idNo: null
      },
      action: {
        lists: [],
        values: [],
        search: null
      },
      loading: {
        submit: false,
        cate: false,
        add: false
      },
      chooseRoleId: null,
      chooseCate: null
    }
  },
  watch: {
    data: {
      deep: true,
      handler () {
        this.initUserData()
      }
    }
  },
  computed: {
    sysRoles: {
      get () {
        return this.$store.getters.roleList
      }
    },
    userRoleDatas () {
      return this.userData.roleIds.map(id => this.sysRoles.find(v => v.key === id))
    },
    actionListsShow () {
      let authIds = this.action.values.map(v => v.key)
      let search = this.action.search
      // !authIds.length 控制取反实现 当前已选项目不再展示在checkbox中
      return !authIds.length && !search ? this.action.lists : this.action.lists.filter(v => {
        if (authIds.includes(v.key)) {
          return false
        } else if (search) {
          return v.label.indexOf(search) > -1
        } else {
          return true
        }
      })
    }
  },
  methods: {
    open (data) {
      this.data = data
      this.initUserData()
      this.show = true
    },
    initUserData () {
      if (this.data) {
        this.userData.phone = this.data.phone
        this.userData.name = this.data.name
        this.userData.roleIds = this.data.roleIds

        this.setChooseRoleId(this.userData.roleIds.length > 0 ? this.userData.roleIds[0] : null)

        let userAuthData = (this.data.roles ? this.data.roles.map(v => v.authorities).flat() : [])
        this.userAuthData = cates.map(v => {
          const auths = userAuthData.filter(auth => v.key === auth.objType)
          return Array.from(new Set(auths.map(auth => auth.roleId))).map(roleId => {
            return {
              objType: v.key,
              ids: auths.filter(auth => auth.roleId === roleId).map(v => v.objId),
              roleId: roleId
            }
          })
        }).flat()
      } else {
        this.userData.phone = null
        this.userData.name = ''
        this.userData.roleIds = []
        this.setChooseRoleId(null)
        this.userAuthData = []
      }
    },
    getRoleAuthConfig (roleId) {
      const role = this.sysRoles.find(v => v.key === roleId)
      const dataAuthorityConfig = !role || !role.data.dataAuthorityConfig ? [] : role.data.dataAuthorityConfig.split(',')
      return dataAuthorityConfig
    },
    setChooseRoleId (roleId) {
      this.chooseRoleId = roleId
      if (this.chooseRoleId) {
        const config = this.getRoleAuthConfig(this.chooseRoleId)
        this.cates = cates.filter(v => config.includes(v.key))
        this.setChooseCate(this.cates.length > 0 ? this.cates[0] : null)
      } else {
        this.setChooseCate(null)
      }
    },
    setChooseCate (cate) {
      this.chooseCate = cate
      this.loadActionPlane(cate)
    },
    async loadActionPlane (cate, reLoad = false) {
      if (cate) {
        this.loading.cate = true
        this.action.lists = await this.$store.dispatch(cate.api, reLoad)
        this.loading.cate = false
      }
      this.loadActionValue()
    },
    loadActionValue () {
      const roleId = this.chooseRoleId
      const key = this.chooseCate ? this.chooseCate.key : null
      const auths = this.userAuthData.find(auth => auth.objType === key && auth.roleId === roleId)
      const authIds = auths ? auths.ids : []
      this.action.values = this.action.lists.filter(v => authIds.includes(v.key))
      if (this.action.values.length !== authIds.length) {
        this.$dialog.info({title: '系统提示', content: '所选角色下数据权限异常，原有数据权限中部分数据已被删除，请重新配置并保存。'})
      }
    },
    selectAll () {
      this.action.values = this.action.lists.slice(0)
      this.updateUserAuthData()
    },
    selectOff () {
      let keys = this.action.values.map(v => v.key)
      this.action.values = this.action.lists.filter(v => {
        return !keys.includes(v.key)
      })
      this.updateUserAuthData()
    },
    valuesChange (values) {
      this.action.values = this.action.lists.filter(v => {
        return Array.isArray(values) ? values.includes(v.key) : values === v.key
      })
      this.updateUserAuthData()
    },
    onRemove (value) {
      this.action.values = this.action.values.filter(v => {
        return v.key !== value.key
      })
      this.updateUserAuthData()
    },
    updateUserAuthData () {
      if (this.chooseCate && this.chooseRoleId) {
        if (!this.userAuthData.find(v => v.objType === this.chooseCate.key && v.roleId === this.chooseRoleId)) {
          this.userAuthData.push({
            objType: this.chooseCate.key,
            ids: [],
            roleId: this.chooseRoleId
          })
        }
        this.userAuthData.forEach(v => {
          if (v.objType === this.chooseCate.key && v.roleId === this.chooseRoleId) {
            v.ids = this.action.values.map(v => v.key)
          }
        })
      }
    },
    dealCurrentAuthoritiesData () {
      let data = this.userAuthData.filter(v => v.objType !== 'position')
      this.userAuthData.filter(v => v.objType === 'org' && v.ids.length > 0).forEach((v) => {
        let positionIds = [-1]
        getpositionIdsByOrgIds({ orgIds: v.ids, child: this.orgTree, positionIds, find: false })
        data.push({
          objType: 'position',
          ids: positionIds,
          roleId: v.roleId
        })
      })
      return data
    },
    async submit () {
      if (!this.$refs.form.verifier()) {
        this.$dialog.info({title: '提示', content: '请正确填写表单'})
        return false
      }
      try {
        this.loading.submit = true
        let userData = Object.assign({}, this.data, this.userData)
        let uid = userData && userData.id ? userData.id : null
        if (uid) {
          delete userData.roles
          await userRequest.update(userData.id, userData)
          this.$notice.success({ title: '系统提示', desc: '用户修改完成' })
        } else {
          uid = await userRequest.add(userData)
          this.$notice.success({ title: '系统提示', desc: '用户新增成功' })
        }
        this.$store.dispatch('loadUserList', true)

        if (uid) {
          await ucenterRequest.updateAuthorities(uid, this.dealCurrentAuthoritiesData())
        }
        this.loading.submit = false

        this.userData.phone = null
        this.userData.name = null
        this.userData.roleIds = []
        this.userAuthData = []
        
        this.newUser.name = null
        this.newUser.code = null
        this.newUser.idNo = null

        this.action.lists = []
        this.action.values = []
        this.action.search = null

        this.chooseRoleId = null
        this.chooseCate = null

        this.show = false

        this.$emit('reload')
      } catch (error) {
        this.loading.submit = false
      }
    },
    async addUser () {
      if (this.$refs.add.verifier()) {
        this.loading.add = true
        let userInfo = await workerRequest.add(this.newUser)
        await this.loadActionPlane(this.chooseCate, true)
        this.newUser.name = null
        this.newUser.code = null
        this.newUser.idNo = null
        this.loading.add = false
        this.valuesChange(userInfo.id)
        this.$notice.success('添加成功')
      }
    }
  },
}
</script>